import throttle from "lodash/throttle";
import { renderReact } from "hypernova-react";

const components = {};
let initialized = false;

const execComponents = throttle(() => {
  Object.keys(components).forEach((name) => {
    const element = document.querySelector(`[data-hypernova-key='${name}']`);
    if (!element) return;
    if (element.getBoundingClientRect().top < window.innerHeight * 2) {
      components[name]().then((module) => {
        renderReact(name, module.default);
      });
      delete components[name];
    }
  });

  if (!Object.keys(components).length) {
    window.removeEventListener("scroll", execComponents);
    initialized = false;
  }
}, 300);

const initScrollListener = () => {
  window.addEventListener("scroll", execComponents);
  initialized = true;
};

const renderReactAsync = (name, callback) => {
  components[name] = callback;
  if (!initialized) initScrollListener();
  execComponents();
};

export default renderReactAsync;
