/* eslint-disable import/first */

import "element-closest";

let hasNativeWeakMap;

if (typeof window !== "undefined") {
  hasNativeWeakMap = "WeakMap" in window;
}

import "babel-polyfill";

if (typeof window !== "undefined" && !hasNativeWeakMap) {
  window.WeakMap = window.Map;
}
