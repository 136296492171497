import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import "react-select/dist/react-select.css";
import styles from "./styles/Form.sass";

class VotingSpecialForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCity: null,
      selectedCinema: null,
    };
  }

  selectCity = (city) => {
    this.setState({
      selectedCity: city,
      selectedCinema: null,
    });
  }

  selectCinema = (cinema) => {
    this.setState({ selectedCinema: cinema });
  }

  render() {
    return (
      <form method="post" action="/deutschlandslieblingskino/danke">
        {this.props.cinema ?
          <input id="cinema" name="voting_special[cinema_id]" type="hidden" value={this.props.cinema} />
          :
          [
            <div className={styles.formRow}>
              <Select
                required
                className={styles.select}
                onChange={(newValue) => { this.selectCity(newValue); }}
                options={Object.keys(this.props.data).map(city => ({ label: city, value: city }))}
                placeholder="Wähle deine Stadt*"
                value={this.state.selectedCity}
              />
            </div>,
            this.state.selectedCity !== null &&
            <div className={styles.formRow}>
              <Select
                id="cinema"
                name="voting_special[cinema_id]"
                className={styles.select}
                onChange={this.selectCinema}
                autofocus
                required
                options={this.props.data[this.state.selectedCity.label]}
                placeholder="Wähle dein Kino*"
                value={this.state.selectedCinema}
              />
            </div>,
          ]
        }
        <div className={styles.formRow}>
          <input
            className={styles.textInput}
            id="name"
            type="text"
            name="voting_special[name]"
            placeholder="Dein Vor- und Nachname"
            aria-label="Dein Vor- und Nachname"
          />
        </div>
        <div className={styles.formRow}>
          <input
            className={styles.textInput}
            id="email"
            type="email"
            name="voting_special[email]"
            required
            placeholder="Deine E-Mail-Adresse*"
            pattern="^[^@\s]+@[^@\s]{2,}\.[^\s]{2,}$"
            aria-label="Deine E-Mail-Adresse"
          />
        </div>
        <div className={styles.formRowLargeGap}>
          <div className={styles.checkboxWrapper}>
            <input className={styles.termsCheckbox} id="terms" type="checkbox" name="terms" required />
            <label className={styles.termsLabel} htmlFor="terms">
              <span className={styles.customRadio} />
              <div className={styles.termsText}>
                Ich akzeptiere die <a href={this.props.termsUrl} target="_blank">Teilnahmebedingungen.*<span className={styles.linkArrow} /></a>
              </div>
            </label>
          </div>
        </div>
        <div className={styles.formRowLargeGap}>
          <div className={styles.formHint}>
              Mit * gekennzeichnete Felder sind Pflichtfelder.
          </div>
        </div>
        <input type="hidden" id="embed" name="embed" value={this.props.embed} />
        <input type="hidden" id="embed_light" name="embed_light" value={this.props.embedLight} />
        <div className={styles.formRowLargeGap}>
          <input
            className={styles.submitButton}
            type="submit"
            value="Absenden"
          />
        </div>
      </form>
    );
  }
}

VotingSpecialForm.propTypes = {
  data: PropTypes.object.isRequired,
  termsUrl: PropTypes.string.isRequired,
  cinema: PropTypes.number,
  embed: PropTypes.bool,
  embedLight: PropTypes.bool,
};

VotingSpecialForm.defaultProps = {
  cinema: null,
  embed: false,
  embedLight: false,
};

export default VotingSpecialForm;
