import React from "react";
import PropTypes from "prop-types";

import styles from "./styles/Countdown.sass";

const calculateCountdown = (endTime) => {
  const countdownDate = new Date(endTime).getTime();
  const timeNow = new Date().getTime();
  const timeLeft = countdownDate - timeNow;

  if (timeLeft < 0) return null;

  const days = Math.floor(timeLeft / 86400000);
  const hours = Math.floor((timeLeft % 86400000) / 3600000);
  const minutes = Math.floor((timeLeft % 3600000) / 60000);
  const seconds = Math.floor((timeLeft % 60000) / 1000);

  let first;
  let second;
  let third;

  if (days > 0) {
    first = { title: "Tage", value: days };
    second = { title: "Stunden", value: `0${hours}`.slice(-2) };
    third = { title: "Minuten", value: `0${minutes}`.slice(-2) };
  } else {
    first = { title: "Stunden", value: hours };
    second = { title: "Minuten", value: `0${minutes}`.slice(-2) };
    third = { title: "Sekunden", value: `0${seconds}`.slice(-2) };
  }

  return { first, second, third };
};

class Countdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = calculateCountdown(this.props.endTime);
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      const countdownValues = calculateCountdown(this.props.endTime);

      if (countdownValues === null) { return clearInterval(intervalId); }

      return this.setState(countdownValues);
    }, 1000);
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <div>
          <div className={styles.value}>{this.state.first.value}</div>
          <div className={styles.title}>{this.state.first.title}</div>
        </div>
        <div>
          <div className={styles.value}>:</div>
        </div>
        <div>
          <div className={styles.value}>{this.state.second.value}</div>
          <div className={styles.title}>{this.state.second.title}</div>
        </div>
        <div>
          <div className={styles.value}>:</div>
        </div>
        <div>
          <div className={styles.value}>{this.state.third.value}</div>
          <div className={styles.title}>{this.state.third.title}</div>
        </div>
      </div>
    );
  }
}

Countdown.propTypes = {
  endTime: PropTypes.number.isRequired,
};

export default Countdown;
