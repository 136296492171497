/* eslint-disable import/first */

import { renderReact } from "hypernova-react";

import "../polyfills";
import { addDomLoadedCallback } from "../helper/domHelper";

import renderReactAsync from "../helper/renderReactAsync";
import Countdown from "../votingSpecial/Countdown";
import VotingSpecialForm from "../votingSpecial/Form";

const renderReactComponents = () => {
  renderReact("Countdown", Countdown);
  renderReact("VotingSpecialForm", VotingSpecialForm);
  renderReactAsync("VotingSpecialFormLinkGenerator", () => import("../votingSpecial/FormLinkGenerator"));
  renderReactAsync("VotingSpecialSponsorSlider", () => import("../votingSpecial/SponsorSlider"));
  renderReactAsync("VotingSpecialEmbedCode", () => import("../votingSpecial/VotingSpecialEmbedCode"));
  renderReactAsync("VotingSpecialStatsChart", () => import("../votingSpecial/VotingSpecialStatsChart"));
};

addDomLoadedCallback(renderReactComponents);
