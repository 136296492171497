export const width = (element) => {
  const style = getComputedStyle(element);
  return element.clientWidth - parseInt(style.paddingLeft, 10) - parseInt(style.paddingRight, 10);
};

export const fullWidth = (element) => {
  const style = getComputedStyle(element);
  return element.scrollWidth - parseInt(style.paddingLeft, 10) - parseInt(style.paddingRight, 10);
};

export const widthWithPadding = element => element.clientWidth;

export const fullWidthWithPadding = element => element.scrollWidth;

export const widthWithBorder = element => element.offsetWidth;

export const widthWithMargin = (element) => {
  const style = getComputedStyle(element);
  return element.offsetWidth + parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
};

const domLoaded = (callback) => {
  document.removeEventListener("DOMContentLoaded", domLoaded);
  window.removeEventListener("load", domLoaded);
  callback();
};

export const addDomLoadedCallback = (callback) => {
  if (typeof document !== "undefined" && document.readyState !== "loading") {
    callback();
  } else {
    document.addEventListener("DOMContentLoaded", domLoaded.bind(null, callback));
    window.addEventListener("load", domLoaded.bind(null, callback));
  }
};

export const inputFocus = () => {
  const { activeElement } = document;
  if (activeElement.closest("[data-use-focus=false]")) return false;
  const { nodeName } = activeElement;
  return (
    nodeName === "TEXTAREA" ||
    (nodeName === "INPUT" && ["checkbox", "radio", "hidden"].indexOf(activeElement.getAttribute("type")) === -1) ||
    (nodeName === "DIV" && activeElement.isContentEditable)
  );
};
